<template>
    <consultoria-form operacao="INSERIR" @salvar="inserir($event)" :cancelar="cancelar" :erros="erros" :services="obterServices()"></consultoria-form>
</template>

<script>
import Services from './services';
import ConsultoriaForm from './ConsultoriaForm';

export default {
    components: {
        ConsultoriaForm,
    },

    data() {
        return {
            erros: [],
        };
    },

    methods: {
        obterServices() {
            return Services;
        },

        cancelar() {
            this.$router.push({ name: 'Utilidades_Consultorias' });
        },

        inserir(consultoriaInserida) {
            this.$store.dispatch('addRequest');
            Services.inserir(consultoriaInserida).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Utilidades',
                        detail: 'Consultoria inserida com sucesso',
                        life: 3000,
                    });
                    this.toDetalhar(response.data);
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar(consultoriaId) {
            this.$store.dispatch('setAtualizar', true);
            this.$router.push({
                name: 'Utilidades_Consultorias_Detalhar',
                params: {
                    id: consultoriaId,
                },
            });
        },
    },
};
</script>
